<template>
  <div class="text-3xl font-bold">
    <a v-if="$route.name === 'Inicio'" href="#inicio" class="flex items-center">
      <img src="/images/logo.png" class="w-24" alt="Logo" />
      <h1 class="xl:text-secondary">TuServiWeb</h1>
    </a>
    <router-link v-else to="/" class="flex items-center">
      <img src="/images/logo.png" class="w-24" alt="Logo" />
      <h1 class="xl:text-secondary">TuServiWeb</h1>
    </router-link>
  </div>
</template>

<script>
export default {
    name: "Logo",
};
</script>