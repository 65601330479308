<template>
<h2 class="text-center w-full text-4xl font-bold pt-0 sm:pt-32" id="planes">Planes</h2>
  <div class="absolute bg-sixth figure-2 plan-height w-full"></div>
  <div class="relative z-0 mx-4">
    <div class="w-full flex justify-center lg:contents">
      <div>
        <CardPrice />
      </div>
    </div>
    
  </div>
</template>

<script>
import CardPrice from "@/components/Inicio/Planes/CardPrice";
export default {
  name: "Planes",
  components: {
    CardPrice,
  },
};
</script>

<style>
</style>