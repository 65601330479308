<template>
  <section
    class=" max-w-full
      grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4
      w-full
      py-12
    "
  >
    <CardPriceArticle v-for="plan in planes" :key="plan.nombre" :plan="plan" />
  </section>
</template>

<script>
import CardPriceArticle from "@/components/Inicio/Planes/CardPriceArticle";
export default {
  name: "CardPrice",
  components: {
    CardPriceArticle,
  },
  data() {
    return {
      planes: [
        {
          nombre: "Estudiantil",
          dominio: true,
          precio: 1000000,
          tiempo: "1 año",
          caracteristicas: [
            "Soporte 24/7",
            "Ancho de banda: 10GB",
            "Espacio de almacenamiento: 5GB",
            "Optimización y manejo de cache",
            "Garantia de uptime 99.9%",
            "Administrador de CPANEL",
            "Bases de datos MariaDB: 1",
            "Sendmail para notificaciones: 1",
            "Softaculous: Básico",
            "FTP: 1",
          ],
        },
        {
          nombre: "Silver",
          dominio: true,
          precio: 400000,
          tiempo: "1 año",
          important: true,
          caracteristicas: [
            "Certificado SSL",
            "Soporte 24/7",
            "Potencia de procesamiento y memoria x4",
            "Ancho de banda: 100GB",
            "Espacio de almacenamiento: 50GB",
            "Antivirus online",
            "Optimización y manejo de cache",
            "Garantia de uptime 99.9%",
            "Administrador de CPANEL",
            "Subdominios: 20",
            "Bases de datos MariaDB: 40",
            "Sendmail para notificaciones: 20",
            "Personalización de versión de PHP",
            "Composer",
            "Puesta en marcha de frameworks",
            "Shell",
            "Softaculous: Avanzado",
            "Gestión de SEO",
            "FTP: 40",
            "Asistencia Backup",
          ],
        },
        {
          nombre: "Gold",
          dominio: true,
          precio: 700000,
          tiempo: "1 año",
          important: true,
          caracteristicas: [
            "Certificado SSL",
            "Soporte 24/7",
            "Potencia de procesamiento y memoria x4",
            "Ancho de banda: Ilimitado",
            "Espacio de almacenamiento: Ilimitado",
            "Antivirus online",
            "Optimización y manejo de cache",
            "Garantia de uptime 99.9%",
            "Administrador de CPANEL",
            "Subdominios: 100",
            "Bases de datos MariaDB: Ilimitado",
            "Sendmail para notificaciones: Ilimitado",
            "Personalización de versión de PHP",
            "Composer",
            "Puesta en marcha de frameworks",
            "Shell",
            "Dirección IP estática",
            "Softaculous: Full",
            "Gestión de SEO",
            "FTP: Ilimitado",
            "Asistencia Backup",
          ],
        },
        {
          nombre: "Básico",
          dominio: true,
          precio: 200000,
          tiempo: "1 año",
          caracteristicas: [
            "Soporte 24/7",
            "Ancho de banda: 50GB",
            "Espacio de almacenamiento: 20GB",
            "Optimización y manejo de cache",
            "Garantia de uptime 99.9%",
            "Administrador de CPANEL",
            "Subdominios: 2",
            "Bases de datos MariaDB: 5",
            "Sendmail para notificaciones: 3",
            "Personalización de versión de PHP",
            "Softaculous: Medio",
            "FTP: 10",
            "Asistencia Backup",
          ],
        },
      ],
    };
  },
};
</script>

<style>
</style>