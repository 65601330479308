<template>
  <div
    class="
      w-screen
      h-screen
      bg-primary
      figure-6
      flex flex-col
      justify-center
      items-center
    "
  >
    <div class="mb-5 flex flex-col items-center px-6 sm:px-0">
      <p class="text-5xl font-bold mb-6">¡Ooooops!</p>
      <p class="text-2xl font-semibold text-center">
        Parece que la página que buscas esta <br class="hidden sm:block" />
        fuera de servicio o no existe.
      </p>
      <router-link
        to="/"
        class="
          mt-5
          px-5
          py-3
          text-white
          bg-terciary
          rounded-xl
          border-l-4 border-b-4 border-quarter
          hover:bg-quarter
          hover:border-terciary
          hover:text-secundary
          transition
          delay-75
          duration-300
          ease-in-out
        "
      >
        Regresar
      </router-link>
    </div>
    <div class="w-6/12 hidden sm:block">
      <Svg404 />
    </div>
  </div>
</template>

<script>
import Svg404 from "@/components/SvgComponents/Svg404";
export default {
  name: "PageNotFound",
  components: {
    Svg404,
  },
};
</script>

<style scoped>
.figure-6 {
  clip-path: polygon(0 0, 70% 0%, 100% 30%, 100% 100%, 30% 100%, 0% 70%);
}
</style>