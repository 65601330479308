<template>
  <nav
    id="navbar"
    class="
      px-4
      py-4
      xl:py-6
      bg-white
      shadow
      w-full
      z-50
      transition-colors
      duration-500
    "
    :class="{ 'xl:bg-transparent xl:shadow-none': !showWhiteBackground, 'xl:fixed': $route.name === 'Inicio' }"
  >
    <div
      class="
        container
        mx-auto
        flex flex-col
        xl:flex-row
        items-center
        justify-between
      "
    >
      <div class="w-full flex flex-row items-center justify-between">
          <Logo />
        <div>
          <button v-show="!isVisible" class="xl:hidden" @click="toggle">
            <svg
              class="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M4 6h16M4 12h16M4 18h16"
              ></path>
            </svg>
          </button>

          <button v-show="isVisible" class="xl:hidden" @click="toggle">
            <svg
              class="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M6 18L18 6M6 6l12 12"
              ></path>
            </svg>
          </button>
        </div>
      </div>
      <div
        class="
          mt-4
          xl:mt-0
          w-full
          xl:space-x-4
          xl:text-right
          xl:block
          text-secundary
        "
        :class="{ hidden: !isVisible }"
      >
        <NavbarLink
          v-for="route in routes"
          :key="route.label"
          :to="route.to"
          :label="route.label"
        />
      </div>
    </div>
  </nav>
</template>

<script>
import NavbarLink from "@/components/NavbarLink";
import Logo from "@/components/Logo";
import { useToggle } from "@/composables/useToggle";
import { ref } from "@vue/reactivity";
export default {
  components: {
    NavbarLink,
    Logo
  },
  props: {
    routes: {
      type: Array,
      require: true,
    }
  },
  setup() {
    let { isVisible, toggle } = useToggle();
    let showWhiteBackground = ref(false);

    document.addEventListener("scroll", function () {
      let bodyTopPosition = document.body.getBoundingClientRect().top;

      if (bodyTopPosition < -150) {
        showWhiteBackground.value = true;
      } else {
        showWhiteBackground.value = false;
      }
    });

    return {
      isVisible,
      toggle,
      showWhiteBackground,
    };
  },
};
</script>

<style>
</style>