import { createRouter, createWebHistory } from 'vue-router'
import Inicio from '../views/Inicio.vue'
import Article from '../views/Article.vue'
import PageNotFound from '../views/PageNotFound.vue'

const routes = [
  {
    path: '/',
    name: 'Inicio',
    component: Inicio
  },
  {
    path: '/article/:slug',
    name: 'article.show',
    component: Article,
  },
  {
    path:  '/:pathMatch(.*)*',
    component: PageNotFound,
    name: 'PageNotFound'
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
