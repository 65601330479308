<template>

  <a v-if="$route.name === 'Inicio'" class="block sm:inline-block sm:px-4 py-2 hover:text-quarter transition-colors duration-300"
               :href="to">
               {{label}}
  </a>

    <router-link v-else class="block sm:inline-block sm:px-4 py-2 hover:text-quarter transition-colors duration-300"
             :to="to">
             {{label}}</router-link>
</template>

<script>
export default {
    props: {
        to: {
            type: String,
            required: true,
        },
        label: {
            type: String,
            required: true,
        },
    }
};
</script>

<style>
</style>