<template>
  <div class="flex flex-col sm:flex-row sm:items-center justify-between">
    <div class="flex items-center text-black">
      <div class="ml-1 text-2xl font-bold">{{nombre}}</div>
    </div>
    <div class="mt-2 sm:mt-0 text-sm flex items-center text-gray-700">
      <div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-4 w-4"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>
      </div>
      <div class="ml-1">{{fecha}}</div>
    </div>
  </div>

  <div class="mt-4 sm:mt-4">
    <div v-html="compiledMarkdown"></div>
  </div>
</template>

<script>
import marked from 'marked'
export default {
  name: "ArticleCard",
  props: {
      nombre: {
          type: String,
          required: true,
      },
      texto: {
          type: String,
          required: true,
      },
      fecha: {
          type: String,
          required: true,
      }
  },
  computed: {
      compiledMarkdown() {
          return marked(this.texto);
      }
  }
};
</script>
