<template>
  <div class="bg-sixth shadow-2xl">
    <Navbar :routes="routes"/>
  </div>
  <div class="my-10">
    <div class="container mx-auto px-4 sm:px-0 xl:px-4 py-8 sm:py-10 shadow-2xl rounded bg-white">
      <ArticleCard v-if="$route.path === '/article/servicios-educativos'" :nombre="texts.ServiciosEducativos.nombre" :texto="texts.ServiciosEducativos.texto" :fecha="texts.ServiciosEducativos.fecha" />
      <ArticleCard v-if="$route.path === '/article/mantenimiento-y-redes'" :nombre="texts.MantenimientoYRedes.nombre" :texto="texts.MantenimientoYRedes.texto" :fecha="texts.MantenimientoYRedes.fecha" />
      <ArticleCard v-if="$route.path === '/article/hosting-y-dominios'" :nombre="texts.HostingYDominio.nombre" :texto="texts.HostingYDominio.texto" :fecha="texts.HostingYDominio.fecha" />
      <ArticleCard v-if="$route.path === '/article/trabajo-de-grado'" :nombre="texts.TrabajoDeGrado.nombre" :texto="texts.TrabajoDeGrado.texto" :fecha="texts.TrabajoDeGrado.fecha" />
    </div>
  </div>
  

</template>


<script>
import Navbar from "@/components/Navbar";
import ArticleCard from "@/components/Article/ArticleCard"
export default {
  components: {
    Navbar,
    ArticleCard
  },
  created() {
    if (this.$route.path !== '/article/servicios-educativos'
    && this.$route.path !== '/article/mantenimiento-y-redes'
    && this.$route.path !== '/article/hosting-y-dominios'
    && this.$route.path !== '/article/trabajo-de-grado') {
        this.$router.push({ name: 'PageNotFound' });
    }
  },
  data() {
    return {
      routes: [
        {
          label: "Inicio",
          to: "/",
        },
      ],
      texts: {
        HostingYDominio : {
          nombre: "Hosting y Dominio",
          texto: `<p class="text-base">Tus servicios en la web, pone a disposición diferentes plantes de alojamiento web, con variadas características para brindar las características que su negocio necesita. Como agente diferenciador frente a diferentes oferentes del mercado esta:</p>
          <ul class="list-disc mt-4">
          <li class="ml-4">
          Resguardo, seguridad y disponibilidad del nombre del dominio contratado como propietario, sin que este sea comercializado o distribuido de manera irresponsable frente al compromiso y buen nombre que pueda adquirir en el medio virtual.
          </li>
          <li class="mt-2 ml-4">
          En cuanto al hosting, como proveedores le permitimos ajustar de forma directa las caracteristicas del servicio contratado, sin tiempos de espera frente al crecimiento que pueda darse en su empresa y que requiera servicios más robustos, ejemplo: ancho de banda para descargas, espacio de almacenamiento, cantidad de emails corporativos, subdominios, cantidad de bases de datos, servicios de SEO, estadísticas, seguridad, paquetes y plantillas libres, asistentes de configutación,copias de seguridad, etc.
          </li>
          </ul>
          <p class="text-xl font-bold mt-6">Definición de Dominio</p>
          <p class="text-base">Un dominio es el nombre por el cual se encuentra un sitio web en internet, es una dirección fácil de recordar, para encontrar en los buscadores de internet (ejemplo google) y acceder a tu sitio web (desde un navegador de internet como chrome, mozilla firefox, internet explorer, safari, opera, etc); recordar que parte del beneficio de un sitio web está en ser independiente del sistema operativo lo que quiere decir que sin instalar ningun tipo de software podra cargar el sitio web en linux, windows, mac, android, ipad, celuares, etc.
          <br>
          <br>
          Hoy en día disponer de un dominio es imprescindible para una empresa; en el mundo globalizado que se esta actualmente no basta con solo registrar un nombre en la camara de comercio, sino que este nombre comercial sea único a nivel mundial; ya que no se puede repetir.
          <br>
          <br>
          Los dominios pueden hacer referencia a cualquier recurso en Internet. Así pues, usted necesitará registrar el propio dominio de su empresa para que cuando alguien escriba www.nombresuempresa.com el usuario pueda acceder a toda la información que usted tiene publicada en este sitio.
          <br>
          <br>
          Al tener un dominio también puede tener asociadas a el las cuentas de correo personalizadas que su empresa necesite, ejemplo: ventas@nombredesuempresa.com, ó cualqueir nombre que desee (reemplazando ventas) ya que el dominio es de su propiedad. Para comprender mejor esto, es como tener un nombre y un apellido, el apellido de un bebe recien nacido esta definido por el de sus padres, pero el nombre no esta sujeto a ninguna regla por lo cual el padre o la madre podrá colocar el nombre deseado, no asi de fácil con el apellido.
          <br>
          <br>
          Un dominio sirve para identifiar a su empresa o a un sitio web en Internet, e ir ganando un prestigio, respeto, credibilidad y mostrar solidez a sus clientes.
          </p>
          <p class="text-xl font-bold mt-6">Definición de Hosting</p>
          <p class="text-base">
          Este termino de alojamiento en la web, hace referencia al servicio para almacenar la información, ya sean imagenes, fotos, videos, musica, texto, etc en la nube para garantizar su disponibilidad en la web; que tiene toda una infraestructura montada para garantizar la seguridad, disponibilidad y velicidad de la información las 24 horas del día, durante los 30 dias del mes y los 12 meses del año. Este servicio en combinación con el dominio son los que permite tener el espacio web para montar el sitio web deseado. Ofrecemos el mejor servicio, en disponbilidad, espacio y respaldo de la información a nuestros clientes para que esten tranquilos incrementandos sus indices de productividad.
          </p>
          `,
          fecha: "28/07/2021",
        },
        ServiciosEducativos : {
          nombre: "Servicios Educativos",
          texto: `<p class="text-base">Tus servicios en la web le acompaña en procesos de instalación configuación, asesoría y auditoria de licenciamiento software para evitar perdidas en la información, evitar inconvenientes con la DIAN, atender requerimientos de organismos gubernamientales como la contraloria y evitar demandas por parte de las casas desarrolladoras de software.
          <br>
          <br>
          Por una parte, las instituciones educativas tienen en sus instalaciones equipos de computo con sistema operativo windows, la suite de office (word, excel, powerpoint, entre otros) que requieren de legalizar su uso desde las sinergias que compete al sector educativo, que son totalmente diferentes a las que aplica para el sector empresarial.
          <br>
          <br>
          Por otra parte, tambien requieren mecanismos para evitar la perdida de información con la instalación y configuración de antivirus, antimalware, antispyware, entre otros; que permitan asegurar y blindar la información como el bien má preciado en el mundo digital.
          <br>
          <br>
          Adicionalmente, la configuración e integración de herramientas TIC, tecnología educativa desde servicios de Clouding para optimizar procesos tanto en labores administrativas como educativas; conectando y mejorando los modos, medios de comunicación en la generación de productos, almacenamiento, distribución, publicación y divulgación de resultados.
          <br>
          <br>
          Tus servicios en la web, le brina acompañamiento y entrenamiento en la publicación y elaboración de sitios web con material educativo didáctico interactivo, que facilite los procesos de control, seguimiento, enseñanza, aprendizaje y evaluación del mismo.</p>`,
          fecha: "28/07/2021",
        },
        MantenimientoYRedes : {
          nombre: "Mantenimiento y redes",
          texto: `<p class="text-base">Tus servicios en la web, con amplio conocimiento sobre las necesidades por parte de las instituciones educativas en velar por el buen funcionamiento de los diferentes equipos de cómputo. Reconoce la importancia de prestar un servicio técnico para atender necesidades de equipos computacionales, salas de cómputo e infraestructura tecnológica para el buen desarrollo de labores adminisitrativas y académicas. El servicio de mantenimiento cubre el año escolar, con acciones oportunas relacionadas con el control de inventarios, labores preventivas, reparaciones con un servicio integral, que van desde la disposición del personal técnico hasta la compra de las piezas necesarias para su reparación; con un plus desde un aplicativo web de tickets que permite conocer el estado, las evidencias y labores realizadas por parte de un delegado en la institución.
          <br>
          <br>
          Lo anterior constituye un valor agregado en la medida que permite tener un canal directo para generar los informes, reportes, solicitud de visitas técnicas y revisión de las acciones frente a los servicios prestados, con un modulo de evaluación y valoración de los resultados como mecanismo de mejoramiento continuo.
          <br>
          <br>
          En Tus Servicios en la Web, le brindamos soporte para sus equipos de computo, de forma preventiva para minimizar futuros daños o perdida de información, o en el peor de los casos si el equipo de computo ya ha sufrido un daño grave que no permite funcionar correctamente, se brinda el soporte necesario para reparar el daño, protegiendo su información como tarea prioritaria.
          </p>`,
          fecha: "28/07/2021",
        },
        TrabajoDeGrado : {
          nombre: "Trabajo de grado",
          texto: `<p class="text-base">Tus servicios en la web, atiende la creciente necesidad de asesoramiento y orientación, revisión, diseño de trabajos de grado en sus diferentes niveles pregrado, postgrado (especialización, maestría) y doctorado. Con un valor agregado en la capacidad para incorporar tecnología educativa innovadora, TICs en la mediación educativa dentro de los trabajos de grado con plataforma virtuales de aprendizaje, objetos virtuales de aprendizaje, portafolios educativos, desarrollo de aplicaciones web, móviles y aplicación de metodologías, teorías pedagógicas de aprendizaje, entre otras que involucran el componente pedagógico, investigativo y tecnológico para finalizar un proyecto de grado, tesis o monografía.
          <br>
          <br>
          Nuestro personal maneja diferentes normas de estilo como APA, ICONTEC, IEEE, etc.
          </p>`,
          fecha: "28/07/2021",
        }
      }
    }
  },
};
</script>

<style>
</style>