<template>
  <div class="pt-0 sm:pt-20 sm:pb-10" id="contactenos">
    <div class="absolute bg-sixth figure-5 h-screen w-full"></div>
    <div class="relative z-10 w-full h-screen">
      <div class="flex px-4 sm:px-24 w-full h-full items-center justify-center">
        <div class="w-9/12 hidden lg:block">
          <SvgContacto class="filter drop-shadow-2xl" />
        </div>
        <div class="w-full h-full sm:ml-10">
          <h2
            class="text-center w-full text-4xl font-bold pt-0 sm:pt-24 lg:pt-32"
          >
            Contáctenos
          </h2>
          <form @submit.prevent="sendEmail">
            <div>
              <div>
                <label
                  for="nombre"
                  class="uppercase font-bold text-sm text-gray-600"
                  >Nombre</label
                >
                <input
                  class="
                    border-2
                    w-full
                    border-gray-300
                    text-gray-900
                    font-semibold
                    mt-2
                    p-2
                    rounded-lg
                    focus:outline-none
                    focus:shadow-outline
                  "
                  type="text"
                  placeholder="Nombre"
                  name="nombre"
                  v-model="form.nombre"
                />
              </div>
              <div class="mt-8">
                <label
                  for="email"
                  class="uppercase text-sm text-gray-600 font-bold"
                  >Email</label
                >
                <input
                  class="
                    w-full
                    border-2
                    font-semibold
                    border-gray-300
                    text-gray-900
                    mt-2
                    p-2
                    rounded-lg
                    focus:outline-none
                    focus:shadow-outline
                  "
                  name="email"
                  type="email"
                  placeholder="Email"
                  v-model="form.email"
                />
              </div>
              <div class="mt-8">
                <label
                  for="telefono"
                  class="uppercase text-sm text-gray-600 font-bold"
                  >Teléfono</label
                >
                <input
                  class="
                    w-full
                    border-2
                    font-semibold
                    border-gray-300
                    text-gray-900
                    mt-2
                    p-2
                    rounded-lg
                    focus:outline-none
                    focus:shadow-outline
                  "
                  name="telefono"
                  type="text"
                  placeholder="Teléfono"
                  v-model="form.telefono"
                />
              </div>
              <div class="mt-8">
                <label
                  for="mensaje"
                  class="uppercase text-sm text-gray-600 font-bold"
                  >Mensaje</label
                >
                <textarea
                  class="
                    w-full
                    h-22
                    border-2
                    font-semibold
                    border-gray-300
                    text-gray-900
                    mt-2
                    p-3
                    rounded-lg
                    focus:outline-none
                    focus:shadow-outline
                  "
                  name="mensaje"
                  v-model="form.mensaje"
                ></textarea>
              </div>
              <div class="mt-8">
                <button
                  class="
                    uppercase
                    text-sm
                    font-bold
                    tracking-wide
                    bg-indigo-500
                    text-gray-100
                    py-3
                    rounded-lg
                    w-full
                    focus:outline-none
                    focus:shadow-outline
                  "
                  type="submit"
                >
                  Enviar
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>

  <div
      class="fixed bottom-0 right-0 z-20" 
      :class="{'hidden': !openAlertBox, 'block': openAlertBox}"
    >
      <div class="p-10">
        <div class="flex items-center text-white text-sm font-bold px-4 py-3 rounded shadow-md"
          :class="{'bg-green-400': color === 'green', 'bg-red-500': color === 'red'}" role="alert">
          <span class="flex">{{messageAlert}}</span>
          <button type="button" class="flex" @click="openAlertBox = false">
            <svg fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" viewBox="0 0 24 24" stroke="currentColor" class="w-4 h-4 ml-4"><path d="M6 18L18 6M6 6l12 12"></path></svg>
          </button>
        </div>
      </div>
    </div>
</template>


<script>
import SvgContacto from "@/components/SvgComponents/SvgContacto";
import emailjs from "emailjs-com";
export default {
  name: "Contactenos",
  components: {
    SvgContacto,
  },
  data() {
    {
      return {
        form: {
          nombre: "",
          email: "",
          telefono: "",
          mensaje: "",
        },
        openAlertBox: false,
        color: 'green',
        messageAlert: 'El mensaje se a enviado con exito',
      };
    }
  },
  methods: {
    sendEmail(e) {
      emailjs
        .sendForm(
          "service_af0rv3p",
          "template_ennkbs3",
          e.target,
          "user_OCkVx7ffv6WNRs2WCVZVw"
        )
        .then(
          () => {
            this.form.mensaje = "";
            this.form.email = "";
            this.form.telefono = "";
            this.form.nombre = "";
            this.openAlertBox = true;
            /* console.log("SUCCESS!", result.status, result.text); Para usar este console log y el de error poner response y error en los () de arriba */
          },
          () => {
            this.color = 'red';
            this.messageAlert = 'El mensaje no se ha enviado con éxito.';
            this.openAlertBox = true;
            /* console.log("FAILED...", error); */
          }
        );
    },
  },
};
</script>

<style scoped>
.figure-5 {
  clip-path: polygon(0 0, 100% 15%, 100% 85%, 0 100%);
}
textarea {
  resize: none;
}
</style>