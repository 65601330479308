<template>
  <div class="pt-6 mb-72 sm:mb-0 sm:pt-20" id="pagos">
    <div class="absolute bg-sixth figure-4 h-screen w-full"></div>
    <div class="relative z-10 w-full h-screen">
      <div class="flex sm:px-24 w-full h-full items-center justify-center">
        <div class="w-9/12 hidden lg:block">
          <SvgPagos class="filter drop-shadow-2xl" />
        </div>
        <div class="w-full h-full ml-10">
          <h2 class="text-center w-full text-4xl font-bold pt-0 sm:pt-12 lg:pt-24">
            Pagos
          </h2>
          <p class="pt-6 text-left">
            Tus Servicios en la Web, por motivos de seguridad tanto para ustedes
            como para nosotros evita recibir dineros personalmente (excepto
            casos especiales); por eso explicamos esta forma de pago que es más
            ágil y segura para todos.
          </p>
          <ul class="ml-5 mt-6 list-disc">
            <li>
              <p>
                Pueden hacer una transferencia electrónica por internet desde el
                portal de su banco.
              </p>
            </li>
            <li>
              <p>
                Puede acercarse a su Banco, y desde allí hacer la consignación.
              </p>
            </li>
          </ul>
          <h3 class="mt-3 text-xl font-bold">Datos de pago:</h3>
          <p class="mt-3 text-lg">TITULAR: - <b>Slendy Paola Ferreira</b> -</p>
          <p class="mt-3 text-lg">CUENTA DE AHORROS - <b>24524409806</b> -</p>
          <p class="mt-3 text-lg">C.C. - <b>63.532.679</b> -</p>
          <p class="mt-3 text-lg">- <b>Banco Caja Social</b> -</p>

          <p class="mt-4">
            Seguidamente de esta consignación simplemente enviar los datos de la
            consignación y el servicio solicitado, por el ticket de este mismo
            portal que se encuentra en la sección, por contactenos o por correo.
            Nota: guarde su comprobante de la consignación hasta que se
            implemente el servicio y este completamente satisfecho; esto para
            efectos de cualquier reclamación.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SvgPagos from "@/components/SvgComponents/SvgPagos";
export default {
  name: "Pagos",
  components: {
    SvgPagos,
  },
};
</script>

<style scoped>
.figure-4 {
  clip-path: polygon(0 15%, 100% 0, 100% 100%, 0 85%);
}
</style>