<template>
  <div class="w-full flex flex-col">
    <article
      class="h-auto"
      :class="{
        'card-price-no-important sm:mt-20': !plan.important,
        'card-price-important': plan.important,
      }"
    >
      <h5 class="font-bold text-3xl">{{ plan.nombre }}</h5>
      <h2
        class="pb-4 flex justify-center font-bold border-b"
        :class="{
          'border-white': plan.important,
          'border-gray-400': !plan.important,
        }"
      >
        <a href="#contactenos" class="py-6">
          <span class="text-xl font-extrabold">
            <!-- {{
              new Intl.NumberFormat("es-ES", {
                style: "currency",
                currency: "COP",
                minimumFractionDigits: 0,
              }).format(plan.precio)
            }} -->Contactanos para conocer el valor de este plan</span
          >
        </a>
      </h2>
      <ul class="text-sm font-semibold">
        <li
          class="pt-4 pb-4 border-b"
          v-for="caracteristica in plan.caracteristicas"
          :key="caracteristica"
          :class="{
            'border-white': plan.important,
            'border-gray-400': !plan.important,
          }"
        >
          {{ caracteristica }}
        </li>
      </ul>
    </article>
  </div>
</template>

<script>
export default {
  name: "CardPriceArticle",
  props: {
    plan: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style>
</style>