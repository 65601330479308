<template>
  <div class="relative z-0 hero-height">
    <div
      class="px-4 w-full sm:px-4 xl:px-16 absolute inset-0 text-secundary flex"
      id="inicio"
    >
      <div class="h-full w-full container mx-auto flex flex-col justify-center">
        <div class="text-6xl font-bold">
          Crea tu sitio web usando los mejores servicios
        </div>
        <div class="mt-4 text-xl">
          Contamos con los mejores planes que te brindan caracteristicas unicas
          para mejorar tu experiencia
        </div>

        <div class="mt-8 text-primary">
          <a
            href="#planes"
            class="
              px-10
              py-4
              bg-terciary
              rounded-xl
              border-l-4 border-b-4 border-quarter
              hover:bg-quarter
              hover:border-terciary
              hover:text-secundary
              transition
              delay-75
              duration-300
              ease-in-out
            "
          >
            Ver planes
          </a>
        </div>
      </div>
      <div class="hidden sm:flex w-8/12 h-full">
        <SvgPrincipal class="filter drop-shadow-xl w-11/12" />
      </div>
    </div>
  </div>
</template>

<script>
import SvgPrincipal from "@/components/SvgComponents/SvgPrincipal";
export default {
    name: "Hero",
    components: {
        SvgPrincipal,
    }
};
</script>

<style>
</style>