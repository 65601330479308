<template>
  <h2
    class="text-center w-full text-4xl font-bold pt-0 sm:pt-32"
    id="servicios"
  >
    Servicios
  </h2>
  <div class="flex justify-center w-full h-full pb-10">
    <div
      class="
        pt-16
        gap-x-24 gap-y-4
        sm:gap-y-20
        grid grid-cols-1
        sm:grid-cols-2
        container
        place-items-stretch
      "
    >
      <div class="flex w-full text-justify items-center">
        <div class="w-11/12 hidden lg:block">
          <SvgHosting class="filter drop-shadow-xl" />
        </div>
        <div class="m-6 sm:ml-6">
          <h3 class="text-2xl text-left font-bold">Hosting y Dominios</h3>
          <p class="text-left mt-3">
            Ponemos a su disposición diferentes plantes de alojamiento web, con
            variadas caracteristicas para brindar una mejor experiencia...
          </p>
          <div class="mt-3">
            <router-link
              :to="{
                name: 'article.show',
                params: { slug: 'hosting-y-dominios' },
              }"
              class="font-bold text-seventh"
            >
              Ver más
            </router-link>
          </div>
        </div>
      </div>
      <div class="flex w-full text-justify items-center">
        <div class="w-11/12 hidden lg:block">
          <SvgMantenimiento class="filter drop-shadow-xl" />
        </div>
        <div class="m-6 sm:ml-6">
          <h3 class="text-2xl text-left font-bold">Mantenimiento y Redes</h3>
          <p class="text-left mt-3">
            Tenemos un amplio conocimiento sobre las necesidades por parte de
            las instituciones educativas en velar por el buen funcionamiento de
            los diferentes equipos de cómputo...
          </p>
          <div class="mt-3">
            <router-link
              :to="{
                name: 'article.show',
                params: { slug: 'mantenimiento-y-redes' },
              }"
              class="font-bold text-seventh"
            >
              Ver más
            </router-link>
          </div>
        </div>
      </div>
      <div class="flex w-full text-justify items-center">
        <div class="w-11/12 hidden lg:block">
          <SvgRecursos class="filter drop-shadow-xl" />
        </div>
        <div class="m-6 sm:ml-6">
          <h3 class="text-2xl text-left font-bold">Servicios Educativos</h3>
          <p class="text-left mt-3">
            Te acompañamos en procesos de instalación, configuración, asesoría y
            auditoria de licenciamiento de software educativo con material
            didáctico interactivo...
          </p>
          <div class="mt-3">
            <router-link
              :to="{
                name: 'article.show',
                params: { slug: 'servicios-educativos' },
              }"
              class="font-bold text-seventh"
            >
              Ver más
            </router-link>
          </div>
        </div>
      </div>
      <div class="flex w-full text-justify items-center">
        <div class="w-11/12 hidden lg:block">
          <SvgGrado class="filter drop-shadow-xl" />
        </div>
        <div class="m-6 sm:ml-6">
          <h3 class="text-2xl text-left font-bold">Trabajo de grado</h3>
          <p class="text-left mt-3">
            Atendemos la creciente necesidad de asesoramiento y orientación,
            revisión, diseño de trabajos de grado en sus diferentes niveles...
          </p>
          <div class="mt-3">
            <router-link
              :to="{
                name: 'article.show',
                params: { slug: 'trabajo-de-grado' },
              }"
              class="font-bold text-seventh"
            >
              Ver más
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SvgHosting from "@/components/SvgComponents/SvgHosting";
import SvgMantenimiento from "@/components/SvgComponents/SvgMantenimiento";
import SvgRecursos from "@/components/SvgComponents/SvgRecursos";
import SvgGrado from "@/components/SvgComponents/SvgGrado";
export default {
  name: "Servicios",
  components: {
    SvgHosting,
    SvgMantenimiento,
    SvgRecursos,
    SvgGrado,
  },
};
</script>