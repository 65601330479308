<template>
  <div class="w-full h-full absolute z-10">
    <Navbar :routes="this.routes" />
    <div class="flex-1">
      <Hero />
      <Planes />
      <Servicios />
      <Pagos />
      <Contactenos />
    </div>
  </div>
  <div class="h-screen w-full bg-primary figure-1"></div>
</template>

<script>
import Navbar from "@/components/Navbar";
import Hero from "@/components/Inicio/Hero/Hero";
import Planes from "@/components/Inicio/Planes/Planes";
import Servicios from "@/components/Inicio/Servicios/Servicios";
import Pagos from "@/components/Inicio/Pagos/Pagos";
import Contactenos from "@/components/Inicio/Contactenos/Contactenos";
export default {
  name: "Inicio",
  components: {
    Navbar,
    Hero,
    Planes,
    Servicios,
    Pagos,
    Contactenos,
  },
  data() {
      return {
        routes: [
      {
        label: "Inicio",
        to: "#inicio",
      },
      {
        label: "Planes",
        to: "#planes",
      },
      {
        label: "Servicios",
        to: "#servicios",
      },
      {
        label: "Pagos",
        to: "#pagos",
      },
      {
        label: "Contáctenos",
        to: "#contactenos",
      }
    ],
      }
  }
};
</script>
